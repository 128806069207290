<template>
<article class="section">
<div class="container">
    <!-- I'm not too fond of this header, but it works for now... -->
    <header class="block">
        <div class="level">
            <div class="level-left">
                <h1 class="title">Migraties</h1>
            </div>
        </div>
    </header>
    <section class="block">
        <b-button @click="migrateUsers">Migreer keycloak gebruikers</b-button>
        <b-button @click="resetLabelPrices">Labelprijzen herberekenen</b-button>
    </section>
</div>
</article>
</template>

<script>
import { api } from '@/utils';
// import { download } from '@/utils/functions';
export default {
    methods: {  
        async migrateUsers() {
            await api.migrateUsers();
        },
        async resetLabelPrices() {
            this.$buefy.dialog.prompt({
                message: `Reset labels created after`,
                inputAttrs: {
                    type: 'datetime-local',
                },
                trapFocus: true,
                onConfirm: async (value) => await api.resetLabelPrices({start_date: value}),
            })
        },
    },
};
</script>
